<template>
  <div class="mt-12">
     <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white py-4 px-4 rounded-xl absolute -top-11 w-full">
          <div class=" relative flex items-center justify-between">
            <div>
              Request list ({{totalRequestCount}})
            </div>
          </div>
        </div>
      </div>
      <div v-if="$route.name !== 'request'">
        <RequestList @jobcount="getReqCount" />
      </div>
      <div v-if="$route.name === 'request'">
        <div class="mt-4 p-2 pb-0">
          <div class="flex flex-wrap mb-2 items-center">
            <!-- <div class="pr-2  text-gray4 heading-5">Entity:</div> -->
            <div class="" v-for="(status, index) in entStatusList" :key="index" >
              <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index, status)" :selected="status.selected" :btnText="status.displayText" :lastInlist="entStatusList.length === index + 1"/>     
            </div>
          </div>
          <div class="flex flex-wrap justify-between items-center ">
            <div class="w-72 mb-4">
              <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Request...`"/>
            </div>
            <div class="mb-4">
              <!-- <Button @buttonAction="addNewRequest(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Request'"/> -->
              <Button @buttonAction="addNewRequest2(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Request'"/>
            </div>
          </div>
        </div>
        <div>
          <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="requestList.length > 0">
            <div v-for="(data, index) in requestList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showRequestDetail(data.requestId)">
              <div class="">
                <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                <p class="text-gray4 heading-5">#{{data.requestId}}</p>
                <div class="flex justify-between items-center">
                  <p v-if="data.requestTitle !== ''" class="text-text1 heading-4 py-1 font-semibold whitespace-pre-line">{{data.requestTitle}}</p>
                  <p v-else class="text-text1 heading-4 py-1 font-semibold whitespace-pre-line">Request for {{data.companyName}}</p>
                  <div class="text-gray4 heading-5 flex items-center">
                    <div v-if="data.isConverted || data.isUnschedule || data.isCompleted || data.isArchived">
                      <span v-if="data.isConverted">Converted<span class="text-success h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.isUnschedule">Unscheduled<span class="text-yellow-300 h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.isCompleted">Assessment Completed<span class="text-warning h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.isArchived">Archived<span class="text-gray3 h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                    </div>
                    <div v-else>
                      <span v-if="data.dateStatus === 'Late'">Late<span class="text-error h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.dateStatus === 'Today'">Today<span class="text-secondary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.dateStatus === 'Upcoming'">Upcoming<span class="text-primary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.dateStatus === 'New'">New<span class="text-primary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="flex justify-between items-center">
                  <p class="text-gray4 heading-5">{{data.companyName}}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div class=" mt-4">
            <Pagination
              :totalDataCount="totalRequestCount"
              :resetPagination="resetPaginationValues"
              @callApiAsPerPagination="getLeadDataFromPagination"/>
          </div>
        </div>
      </div>
     </div>
   </div>
</template>
<script>
import SearchTextInput from '@/View/components/searchTextInput.vue'
var moment = require('moment')
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import RequestList from './components/RequestList.vue'
export default {
  name: "lead",
  components: {
    RequestList,
    Button,
    StatusBtn,
    NoRecords,
    Pagination,
    SearchTextInput,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      entStatusList: [
        {
          vistStatusId: 1,
          displayText: 'New',
          id: 'new',
          selected: false,
        },
        {
          vistStatusId: 3,
          displayText: 'Today',
          id: 'today',
          selected: false,
        },
        {
          vistStatusId: 2,
          displayText: 'Upcoming',
          id: 'upcoming',
          selected: false,
        },
        {
          vistStatusId: 4,
          displayText: 'Late',
          id: 'late',
          selected: false,
        },
        {
          vistStatusId: 8,
          displayText: 'Unscheduled',
          id: 'unschedule',
          selected: false,
        },
        {
          vistStatusId: 5,
          displayText: 'Assessment Completed',
          id: 'assessmentCompleted',
          selected: false,
        },
        {
          vistStatusId: 7,
          displayText: 'Converted',
          id: 'converted',
          selected: false,
        },
        {
          vistStatusId: 6,
          displayText: 'Archived',
          id: 'archived',
          selected: false,
        },
      ],
      empStatusList: [],
      searchForRequest: '',
      requestList: [],
      searchForQuotation: '',
      getReqDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalRequestCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        isActive: true,
        exportData: false,
        sortBy: 'requestId',
        sortOrder: 'DESC',
        entStatusList: [],
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
      },
      showMenuList: false,
      sendEmailReq: false,
    };
  },
  created() {},
  mounted() {
    this.$root.$on("addCustomerHandler", (response) => {
      console.log(response);
      if (response) {
        this.getVisitList()
      }
      this.addLead = false
    });
     this.getReqDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.getVisitList()
  },
  beforeDestroy() {},
  methods: {
  closeBtn () {
    this.sendEmailReq = false
  },
  closeList () {
    this.showMenuList = false
  },
    addNewRequest () {
      this.$router.push({name: 'addRequest', params: {requestId: 0}})
    },
    addNewRequest2 () {
      this.$router.push({name: 'addNewRequest', params: {requestId: 0}})
    },
    showRequestDetail (id) {
      console.log(id);
      this.$router.push({name: 'requestDetail', params: {requestId: id}})
    },
    toggleActiveAndInactiveA (id, dataVal) {
      this.entStatusList.map((data, i) => {
          if (dataVal.id === data.id) {
            if (this.entStatusList[i].selected) {
               this.filterObject.entStatusList = []
               this.entStatusList[i].selected = false
            } else {
                this.filterObject.entStatusList = data.id
               this.entStatusList[i].selected = true
            }
          } else {
            this.entStatusList[i].selected = false
          }
      })
      this.resetPaginationValues = true
      // this.getVisitList()
    },
    searchCall (data) {
      this.searchForRequest = data
      this.getReqDebounce()
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      this.getVisitList(true)
    },
    getReqCount (data) {
      this.totalRequestCount = data
    },
    getVisitList (showlistLoader) {
      if (showlistLoader && this.searchForQuotation !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.requestlist(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForRequest,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.entStatusList,
        0,
        this.filterObject.exportData,
        '',
        '',
        response => {
          if (this.filterObject.exportData) {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.filterObject.exportData = false
          } else {
            this.totalRequestCount = response.Data.count
            let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
            for (let index = 0; index < temp.length; index++) {
              let currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);
              currentDate = new Date(currentDate);
              const cFormated = moment(currentDate).format('YYYY-MM-DD');
              if (temp[index].isUnschedule) {
                temp[index].dateStatus = 'Unschedule';
              } else {
                if ( moment(new Date(temp[index].startTime)).format('YYYY-MM-DD',) === cFormated) {
                  temp[index].dateStatus = 'Today';
                } else if ( new Date(temp[index].startTime).getTime() < currentDate.getTime()) {
                  temp[index].dateStatus = 'Late';
                } else if (new Date(temp[index].startTime).getTime() > currentDate.getTime()) {
                  temp[index].dateStatus = 'Upcoming';
                } else {
                  temp[index].dateStatus = 'New';
                }
                console.log('----------------------------------', temp[index]);
              }
            }
            this.requestList = temp
            this.resetPaginationValues = false
          }
          
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  }
};
</script>
<style scoped>
</style>